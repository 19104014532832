import React from 'react';
import Layout from '@lekoarts/gatsby-theme-minimal-blog/src/components/layout';
import SEO from '@lekoarts/gatsby-theme-minimal-blog/src/components/seo';

export default () => {
  if (typeof window === 'undefined') {
    // Make it work with SSR
    return null;
  }

  const parentRef = React.useRef(null);
  const bzScript = React.useRef(document.createElement('script'));
  const [playerStatus, setPlayerStatus] = React.useState(
    'Waiting for awesomeness...',
  );

  const onScriptEvent = React.useCallback(
    (e: Event) => {
      const script = bzScript.current;

      switch (e.type) {
        case 'load':
          setPlayerStatus(null);
          break;
        default:
          setPlayerStatus('Failed to load player');
          break;
      }

      script.removeEventListener('load', onScriptEvent);
      script.removeEventListener('error', onScriptEvent);
    },
    [bzScript, setPlayerStatus],
  );

  React.useEffect(() => {
    const doc = parentRef.current;
    const script = bzScript.current;

    script.addEventListener('load', onScriptEvent);
    script.addEventListener('error', onScriptEvent);

    script.src = 'https://www.buzzsprout.com/903283.js?container_id=buzzsprout-large-player-903283&player=large';
    script.async = true;
    doc.appendChild(script);
  }, []);

  return (
    <Layout>
      <SEO
        title="BlendIT Podcast"
        description="В BlendIT Podcast мы высказываем своё мнение по поводу тем, касающихся карьеры в современном мире, делимся нашим опытом, обсуждаем интересные жизненные ситуации, говорим о трендах и саморазвитии"
        pathname="/podcast"
      />
      <h1>Meet BlendIT Podcast!</h1>
      <p>
        In a free time, I am a co-host of the BlendIT podcast, where we discuss
        lifestyle, software engineering and a lot of other interesting topics.
        For now,
        {' '}
        <b>content is primarily in Russian language</b>
        .
      </p>
      <p>
        <a
          href="https://aapodcast.buzzsprout.com"
          target="_blank"
          rel="noreferrer"
        >
          Official BlendIT podcast website
        </a>
        <br />
      </p>
      {Boolean(playerStatus) && <b>{playerStatus}</b>}
      <div ref={parentRef} id="buzzsprout-large-player-903283" />
    </Layout>
  );
};
